.node-context-menu {
  background-color: white;
  padding: 10px 10px 5px 10px;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  color: #374469;
}

.node-context-menu .action {
  text-align: left;
  padding-bottom: 5px;
}

.node-context-menu .action button {
  min-width: none;
  margin-right: 10px;
}
