body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  forced-color-adjust: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  height: 100%;
}

* {
  forced-color-adjust: none;
}
div[class$='--Results'],
div[class^='--Results'],
div[class*=' --Results'] {
  z-index: 10001;
}

@-webkit-keyframes fadeInFrames {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeInFrames {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInFrames {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeInFrames ease-in 1;
  -moz-animation: fadeInFrames ease-in 1;
  animation: fadeInFrames ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeInSlow {
  -webkit-animation-duration: 2s;
  -moz-animation-duration: 2s;
  animation-duration: 2s;
}

@media print {
  @page {
    size: auto;
    margin-top: 1cm;
    margin-bottom: 1cm;
  }
  .noneDisplayOnPrint {
    display: none;
  }
  .hideVisibilityOnPrint,
  button {
    visibility: hidden;
  }
  p button {
    visibility: visible;
  }
  p {
    font-size: 1rem !important;
  }
  img,
  svg {
    break-inside: avoid;
    page-break-inside: avoid;
    -webkit-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    box-shadow: none !important;
  }
}
